import * as React from "react"
import {useSpring} from 'react-spring'
import {AnimatedButtonProps} from 'src/components/AnimatedButton'
import ProductButton from 'src/components/ProductButton'
import LinksLayout from 'src/components/LinksLayout'
import {fadeInFromLeftConfig, fadeInFromRightConfig} from 'src/utils/animations'

const KeyboardMouse = (props) => {
  const fadeInFromLeft = useSpring(fadeInFromLeftConfig)
  const fadeInFromRight = useSpring(fadeInFromRightConfig)

  const getFluidImage = (imageName: string) => (
    props.data[imageName].childImageSharp.fluid
  )

  const productList: AnimatedButtonProps[] = [
    {
      backgroundImageData: getFluidImage('Keyboard'),
      link: 'https://www.zsa.io/moonlander/',
      fadeSpringClass: fadeInFromLeft,
      header: 'ZSA Moonlander MK1',
    },
    {
      backgroundImageData: getFluidImage('Mouse'),
      link: 'https://amzn.to/3KTdhVA',
      fadeSpringClass: fadeInFromRight,
      header: 'Logitech MX Master 3',
    },
  ]

  return (
    <LinksLayout>
      {productList.map((linkData) => <ProductButton {...linkData}/> )}
    </LinksLayout>
  )
}

export default KeyboardMouse

export const query = graphql`
  query {
    Keyboard: file(relativePath: { eq: "setup/keyboardmouse/keyboard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Mouse: file(relativePath: { eq: "setup/keyboardmouse/mouse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`